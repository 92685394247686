
<template>
  <div id="app">
    <div id="title"><a></a></div>
    <div id="logo">
      <img src="/logo_no.png" width="100%" /> 
    </div>
    <!-- <img src="/logo.png"  width="rem" /> -->
    <!-- 手机号 -->
    <div id = "loginInput">
      <InputGroup
        type="number"
        placeholder="手机号"
        v-model="phone"
        :btnTitle="btnTitle"
        :disabled="disabled"
        :error="errors.phone"
        @btnClick="getVerifyCode"
      />
      <!-- 输入验证码 -->
      <InputGroup
        type="number"
        v-model="verifyCode"
        placeholder="验证码"
        :error="errors.code"
      />
      <!-- 登录按钮 -->

      <div class="login_btn">
        <button @click="handleLogin" :disabled="isClick" :class="{btn_active:!isClick}">登录</button>
      </div>
    </div>
  <div id="beian"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19143951号-2</a></div>
  </div>
</template>

<script>
import InputGroup from "@/components/InputGroup.vue";
export default {
  name: "App",
  components: {
    InputGroup,
  },
  data() {
    return {
      phone: "", //手机号
      verifyCode: "", //验证码
      btnTitle: "获取验证码",
      disabled: false, //是否可点击
      errors: {phone:"",code:""}, //验证提示信息
      pz:"",
    };
  },
  methods: {
    //获取验证码
    getVerifyCode() {
      if (this.validatePhone()) {
        this.validateBtn();
        let vif = {
          phone: this.phone,
          stamp:new Date().getTime()
        };
        //console.log(vif);
        fetch("/api/getVerifyCode", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(vif),
          //credentials:'include',
        })
          .then((response) => response.json())
          .then((json) => {
           // console.log(json);
              
            //if (json.err) this.errors.phone = json.err;
            if (json.err){
             this.$set(this.errors,'phone',json.err)
              this.pz = ""
              return
            }
            this.pz = json.pz || ""
          
          });
      }
    },

    validatePhone() {
      //判断输入的手机号是否合法
      if (!this.phone) {
        this.errors = {
          phone: "手机号码不能为空",
        };
        // return false
      } else if (!/^1[345678]\d{9}$/.test(this.phone)) {
        this.errors = {
          phone: "请输入正确手机号",
        };
        // return false
      } else {
        this.errors = {};
        return true;
      }
    },

    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },

    handleLogin() {
      //点击发送
      this.errors = {};
      let vif = {
        phone: this.phone,
        code: this.verifyCode,
        pz:this.pz,
      };
      fetch("/api/handleLogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(vif),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.err) {
             this.$set(this.errors,'code',json.err)
              return;
          }
          //alert(window.location.protocol + "//" + json.href)
          let host = this.getQueryString('ref')
          let tolink = "https://www.datasc.net"
          //let tolink = window.location.href.replace(window.location.search,"").replace('login',host)
          //window.location.replace(window.location.protocol + "//" + json.href);
          window.location.replace(tolink);
        });
    },
    getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        //console.log(window.location.href)
        return r[2];
        //return unescape(r[2]);
    };
    return null;
 		}
  },

  computed: {
    //手机号和验证码都不能为空
    isClick() {
      if (!this.phone || !this.verifyCode || !this.pz) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*margin: 80px auto;*/
}

body {
margin:0px;
height:100%;
}

#title {
height:2.5rem;
margin-bottom:80px;
background-color:#26a2ff;
color:white;
display: flex;
align-items: center;
justify-content: center;
/*font-weight:bold;*/
}

#title a {
  letter-spacing:2px
}

#logo {
width:80%;
margin:auto;
}

@media only screen and (min-width: 1280px) {

  #logo {
  width:400px;
  margin:auto;
  }

}

.btn_active {
background-color:#45b97c !important;
}

#loginInput {
width:fit-content;
margin:40px auto;

}


.login_btn {
  margin:2rem auto;
  text-align: center;
}



.login_btn button {
  width:100%;
  border: none;
  border-radius:10px;
  background-color:#d3d7d4;
  font-size:1.1rem;
  height:2.8rem;
}

#beian {
  width:100%;
  align-items: center;
  text-align: center;
  bottom:0px;
  position:absolute;
}

</style>
