<template>
    <div class="text_group">
        <div class="input_group" :class="{'is-invalid': error}">
            <!-- 输入框 -->
            {{placeholder}}
            <input 
                :type="type"
                :placeholder="'请输入' + placeholder"
                :value="value"
                :name="name"
                @input="$emit('input',$event.target.value)"
            >
            <!-- 输入框后面的内容 -->
            <button v-if="btnTitle" @click="$emit('btnClick')" :disabled="disabled">{{btnTitle}}</button>
        </div>
        <!-- 验证提示 -->
        <div v-if="error" class="invalid-feedback">{{error}}</div>
    </div>
</template>
<script>
    export default {
        name:"inputGroup",
        props:{
            type: {
                type: String,
                default: "text"
            },
            placeholder:String,
            value:String,
            name:String,
            disabled:Boolean,
            btnTitle:String,  //input框中的文字
            error:String  //验证不正确提示
        }
    }
</script>

<style scoped>

.text_group{
margin:0px 0px 1.2rem 0px;
height:100%;
font-size:1.1rem;
border-radius:5px;
}

.input_group{
padding:0 0 0.5rem 0;
height:2rem;
margin:0px;
border-bottom:1.2px solid gray;
}

button{
outline:0px;
-webkit-appearance: none; 
border-radius:4px;
border:1.2px solid gray;
color:green;
height:100%;
font-size:1rem;
background-color:#F5F5F5;
padding:1px 6px;
}


input{
outline:0px;
-webkit-appearance: none; 
border:none;
height:100%;
font-size:1.1rem;
margin-left:0.3rem;
width:8rem;
BACKGROUND-COLOR: transparent;
}

input:focus{

outline:none;
border:none;

}



</style>
